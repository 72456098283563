import store from "@/store";
import { getSubdomain } from "@/services/http-service";

/**
 * We do not check acceptance for Empire.
 * Also if previous(from) route is from same module and contain "document" middleware it means that acceptance is already checked in previous route.
 * NOTE: Down side effect is that document acceptance won't be checked if user is on same module whole day without logout.
 */
export default async function document({ to, from, data }) {
  if (!getSubdomain()) {
    return;
  }

  if (
    to.matched[0] === from.matched[0] &&
    from.meta.middlewares &&
    from.meta.middlewares.document
  ) {
    return;
  }

  const response = await store.dispatch(
    "applications/getAcceptedByUser",
    data.applicationId,
    {
      root: true
    }
  );
  if (!response) {
    store.commit(
      "companyDocuments/setRedirectRoute",
      { to, from },
      {
        root: true
      }
    );
    return {
      name: "r_company-documents-accept-documents",
      params: data
    };
  }
  return;
}
