export default {
  path: "/services",
  name: "r_services",
  meta: {
    middlewares: {
      auth: null,
      permission: "SERVICES",
      menuPreferences: "SERVICES"
    }
  },
  component: () => import("../../components/services/Services"),
  children: [
    {
      path: "add-order/:order_resource_id",
      name: "r_services-add-order",
      meta: {
        middlewares: {
          auth: null,
          permission: "SERVICES"
        }
      },
      props: { backLinkName: "r_services" },
      component: () => import("../../components/services/AddServiceOrder"),
      children: [
        {
          path: "item-info/:order_item_id",
          name: "r_services-add-order-item-info",
          meta: {
            middlewares: {
              auth: null,
              permission: "SERVICES"
            }
          },
          props: { backLinkName: "r_services-add-order" },
          component: () => import("../../components/services/ItemInfo")
        }
      ]
    },
    {
      path: "add-booking-order/:order_resource_id/:order_booking_id",
      name: "r_services-add-booking-order",
      meta: {
        middlewares: {
          auth: null,
          permission: "SERVICES"
        }
      },
      props: { backLinkName: "r_services" },
      component: () => import("../../components/services/AddServiceOrder"),
      children: [
        {
          path: "item-info/:order_item_id",
          name: "r_services-add-booking-order-item-info",
          meta: {
            middlewares: {
              auth: null,
              permission: "SERVICES"
            }
          },
          props: { backLinkName: "r_services-add-booking-order" },
          component: () => import("../../components/services/ItemInfo")
        }
      ]
    },
    {
      path: "edit-order/:service_order_id",
      name: "r_services-edit-order",
      meta: {
        middlewares: {
          auth: null,
          permission: "SERVICES"
        }
      },
      props: { backLinkName: "r_services" },
      component: () => import("../../components/services/EditServiceOrder"),
      children: [
        {
          path: "item-info/:order_item_id",
          name: "r_services-edit-order-item-info",
          meta: {
            middlewares: {
              auth: null,
              permission: "SERVICES"
            }
          },
          props: { backLinkName: "r_services-edit-order" },
          component: () => import("../../components/services/ItemInfo")
        },
        {
          path: "history",
          name: "r_services-edit-order-history",
          meta: {
            middlewares: {
              auth: null,
              permission: "SERVICES"
            }
          },
          props: { backLinkName: "r_services-edit-order" },
          component: () => import("../../components/services/History")
        }
      ]
    },
    {
      path: "update-order-status/:service_order_id",
      name: "r_services-update-order-status",
      meta: {
        middlewares: {
          auth: null,
          permission: "SERVICES"
        }
      },
      props: { backLinkName: "r_services" },
      component: () => import("../../components/services/UpdateOrderStatus"),
      children: [
        {
          path: "update-item-status/:service_item_id",
          name: "r_services-update-item-status",
          meta: {
            middlewares: {
              auth: null,
              permission: "SERVICES"
            }
          },
          props: { backLinkName: "r_services-update-order-status" },
          component: () => import("../../components/services/UpdateItemStatus")
        },
        {
          path: "history",
          name: "r_services-update-order-status-history",
          meta: {
            middlewares: {
              auth: null,
              permission: "SERVICES"
            }
          },
          props: { backLinkName: "r_services-update-order-status" },
          component: () => import("../../components/services/History")
        }
      ]
    },
    {
      path: "service-resources",
      name: "r_services-resources",
      meta: {
        middlewares: {
          auth: null,
          permission: "SERVICES"
        }
      },
      props: { backLinkName: "r_services" },
      component: () => import("../../components/services/ServiceResources"),
      children: [
        {
          path: "search-filters",
          name: "r_services-resources-search-filters",
          meta: {
            middlewares: {
              auth: null,
              permission: "SERVICES"
            }
          },
          props: { backLinkName: "r_services-resources" },
          component: () =>
            import("../../components/search/SearchFiltersServiceResources")
        },
        {
          path: ":order_resource_id/service-types",
          name: "r_services-types",
          meta: {
            middlewares: {
              auth: null,
              permission: "SERVICES"
            }
          },
          props: { backLinkName: "r_services-resources" },
          component: () => import("../../components/services/ServiceTypes"),
          children: [
            {
              path: ":order_type_id/add-order",
              name: "r_services-resources-add-order",
              meta: {
                middlewares: {
                  auth: null,
                  permission: "SERVICES"
                }
              },
              props: { backLinkName: "r_services-types" },
              component: () =>
                import("../../components/services/AddServiceOrder"),
              children: [
                {
                  path: "item-info/:order_item_id",
                  name: "r_services-resources-add-order-item-info",
                  meta: {
                    middlewares: {
                      auth: null,
                      permission: "SERVICES"
                    }
                  },
                  props: { backLinkName: "r_services-resources-add-order" },
                  component: () => import("../../components/services/ItemInfo")
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
