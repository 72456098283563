<template>
  <template v-if="isFullscreenPath">
    <FullScreenLayout v-if="isFullscreenPath" />
  </template>
  <template v-else>
    <screen-loader-wrapper v-if="loading && !loggedIn" />
    <DefaultLayout v-else-if="!loggedIn" />
    <LoggedInLayout v-else />
  </template>
</template>

<script>
import { defineAsyncComponent } from "vue";
import { mapActions, mapState } from "vuex";
import { isLoggedIn, getSubdomain } from "@/services/http-service";
import { fullScreenPaths } from "@/services/constants";

export default {
  name: "App",
  components: {
    ScreenLoaderWrapper: defineAsyncComponent(() =>
      import("@/components/global/ScreenLoaderWrapper")
    ),
    DefaultLayout: defineAsyncComponent(() =>
      import("@/components/main-page-layouts/DefaultLayout")
    ),
    LoggedInLayout: defineAsyncComponent(() =>
      import("@/components/main-page-layouts/LoggedInLayout")
    ),
    FullScreenLayout: defineAsyncComponent(() =>
      import("@/components/main-page-layouts/FullScreenLayout")
    )
  },
  data() {
    return {
      isFullscreenPath: false,
      keys: {}
    };
  },
  created() {
    window.addEventListener("keydown", this.detectKey);
    window.addEventListener("keyup", this.releaseKey);
    this.checkIfFullScreenPath();
  },
  beforeUnmount() {
    window.removeEventListener("keydown", this.detectKey);
    window.removeEventListener("keyup", this.releaseKey);
  },
  watch: {
    $route() {
      this.checkIfFullScreenPath();
      if (
        !this.translations &&
        !this.getTranslationsPending &&
        this.$route.name != "r_service" &&
        !getSubdomain()
      ) {
        this.getTranslations();
      }
    }
  },
  computed: {
    ...mapState("global", ["mainMenuStatus", "showRawTranslation"]),
    ...mapState("auth", ["loggedIn"]),
    ...mapState("loader", ["loading"]),
    ...mapState("settings", [
      "companyPreferences",
      "companyTimePreferences",
      "userTimePreferences"
    ]),
    ...mapState("translation", ["translations", "getTranslationsPending"])
  },
  mounted() {
    this.$nextTick(() => {
      this.checkIfFullScreenPath();
      this.checkLoggedInStatus();
    });
  },
  methods: {
    ...mapActions("global", ["setMenuStatus", "setShowRawTranslation"]),
    checkLoggedInStatus() {
      if (!this.loggedIn) {
        const checkLoggedIn = isLoggedIn();
        if (checkLoggedIn) {
          this.$store.commit("auth/setLoggedInStatus", true, {
            root: true
          });
        }
      }
    },
    checkIfFullScreenPath() {
      this.isFullscreenPath =
        typeof this.$route.name === "undefined" ||
        fullScreenPaths.includes(this.$route.name);
    },
    detectKey(e) {
      if (e.code === "KeyT" || e.code === "ControlLeft") {
        if (!this.keys[e.code]) {
          this.keys = {
            ...this.keys,
            [e.code]: e.code
          };
        }
      }

      if (this.keys["KeyT"] && this.keys["ControlLeft"]) {
        this.$store.commit(
          "global/setShowRawTranslation",
          !this.showRawTranslation,
          { root: true }
        );
      }
    },
    releaseKey() {
      this.keys = {};
    }
  }
};
</script>
