import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";
import globalMixin from "@/services/mixins/global/global";

export default {
  namespaced: true,
  state: {
    logsList: null,
    activeState: false
  },
  mutations: {
    setLogsList(state, payload) {
      state.logsList = payload;
    },
    setActiveState(state, payload) {
      state.activeState = payload;
    }
  },
  actions: {
    getLogsList({ commit }) {
      const url = `${apiEndpoints.company.logsExport}`;
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .get(url)
        .then(response => {
          commit("setLogsList", response.data.data);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    downloadCurrentMonthLog({ commit /*, dispatch*/ }) {
      const url = `${apiEndpoints.company.logsExport}/create`;
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .get(url, {
          responseType: "blob",
          exposedHeaders: ["Content-Disposition"]
        })
        .then(response => {
          let blob = new Blob([response.data], {
            type: response.headers["content-type"]
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          const contentDispositionHeader =
            response.headers["content-disposition"];
          let fileName = "Log.csv";
          if (contentDispositionHeader) {
            const headerFileName = globalMixin.methods.extractHeaderFileName(
              contentDispositionHeader
            );
            fileName = headerFileName ? headerFileName : fileName;
          }
          link.download = fileName;
          link.click();
          link.remove();
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    downloadLog({ commit }, logName) {
      const url = `${apiEndpoints.company.logsExport}/${logName}`;
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .get(url, {
          exposedHeaders: ["Content-Disposition"]
        })
        .then(response => {
          let blob = new Blob(["\ufeff" + response.data], {
            type: response.headers["content-type"]
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = `${logName}.csv`;
          link.click();
          link.remove();
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    getActiveState({ commit }) {
      const url = `${apiEndpoints.company.logsConfigs}/1`;
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .get(url)
        .then(response => {
          commit("setActiveState", !!response.data.data.is_active); // convert to boolean
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    toggleActiveLogs({ commit }, activeState) {
      const url = `${apiEndpoints.company.logsConfigs}/1`;
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .put(url, {
          is_active: activeState
        })
        .then(() => {
          commit("setActiveState", !!activeState); // convert to boolean
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    }
  }
};
