const OUTLOOK_MIDDLEWARE = {
  outlook: true,
  auth: null
};

export default {
  path: "/outlook",
  name: "r_outlook",
  meta: {
    middlewares: OUTLOOK_MIDDLEWARE
  },
  component: () => import("../../components/outlook/Outlook"),
  children: [
    {
      path: "duration",
      name: "r_outlook-duration",
      meta: {
        middlewares: OUTLOOK_MIDDLEWARE
      },
      props: {
        backLinkName: "r_outlook"
      },
      component: () => import("../../components/outlook/Duration")
    },
    {
      path: "advanced-search",
      name: "r_outlook-advanced-search",
      meta: {
        middlewares: OUTLOOK_MIDDLEWARE
      },
      component: () => import("../../components/outlook/AdvancedSearch"),
      children: [
        {
          path: "duration",
          name: "r_outlook-advanced-search-duration",
          meta: {
            middlewares: OUTLOOK_MIDDLEWARE
          },
          props: {
            backLinkName: "r_outlook-advanced-search"
          },
          component: () => import("../../components/outlook/Duration")
        }
      ]
    },
    {
      path: "add-resource",
      name: "r_outlook-add-resource",
      meta: {
        middlewares: OUTLOOK_MIDDLEWARE
      },
      props: {
        backLinkName: "r_outlook"
      },
      component: () => import("../../components/outlook/AddResource")
    },
    {
      path: "recurrence",
      name: "r_outlook-recurrence",
      meta: {
        middlewares: OUTLOOK_MIDDLEWARE
      },
      props: {
        backLinkName: "r_outlook"
      },
      component: () => import("../../components/outlook/Recurrence")
    }
  ]
};
