<template>
  <button @click="initQr">
    <span class="svg-icon"
      ><svg><use xlink:href="#cx-foo2-qrcode-38x38"></use></svg
    ></span>
  </button>
</template>

<script>
import { getScreenId } from "@/services/helpers";

export default {
  name: "QrCodeScannerButton",
  props: {
    store: {
      default: "qrCode/setQrActive",
      type: String
    }
  },
  methods: {
    initQr() {
      this.$store.commit(this.store, true, { root: true });
      this.$store.commit(
        "qrCode/setQrScreenId",
        getScreenId(this.findElement()),
        {
          root: true
        }
      );
    }
  }
};
</script>
