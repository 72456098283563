export default {
  namespaced: true,
  state: {
    mainMenuStatus: false,
    errorMessage: null,
    screenId: null,
    showRawTranslation: false,
    isFullWidthScreen: false,
    errorRouteName: null,
    showMenu: true
  },
  getters: {},
  mutations: {
    setMenuStatus(state, payload) {
      state.mainMenuStatus = payload;
    },
    setErrorMessage(state, payload) {
      state.errorMessage = payload;
    },
    setScreenId(state, payload) {
      if (typeof payload === "string") {
        state.screenId = parseInt(payload, 10);
      } else {
        state.screenId = payload;
      }
    },
    setShowRawTranslation(state, payload) {
      state.showRawTranslation = payload;
    },
    setIsFullWidthScreen(state, payload) {
      state.isFullWidthScreen = payload;
    },
    setErrorRouteName(state, payload) {
      state.errorRouteName = payload;
    },
    setShowMenu(state, payload) {
      if (payload === "default") {
        state.showMenu = !state.showMenu;
      } else {
        state.showMenu = payload;
      }
    }
  },
  actions: {
    setMenuStatus({ commit }, payload) {
      commit("setMenuStatus", payload);
    },
    setErrorMessage({ commit }, payload) {
      commit("setErrorMessage", payload);
    },
    setScreenId({ commit }, payload) {
      commit("setScreenId", payload);
    },
    setErrorRouteName({ commit }, payload) {
      commit("setErrorRouteName", payload);
    },
    setShowMenu({ commit }, payload = "default") {
      commit("setShowMenu", payload);
    }
  }
};
