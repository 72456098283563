import { createApp } from "vue";
import { defineAsyncComponent } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/index";
import SvgIcon from "@/components/global/SvgIcon";
import Icon from "@/components/global/Icon";
import { Field, Form as VeeForm } from "vee-validate";
import labelsMixin from "@/services/mixins/global/labels";
import "@/services/calendar-localization-service";
import "@/services/validation-rules";
import "@/styles/index.scss";
import "vue-advanced-cropper/dist/style.css";
import HomeButton from "@/components/global/HomeButton";
import globalMixin from "@/services/mixins/global/global";

const app = createApp(App);

app
  .component(
    "ScreenModal",
    defineAsyncComponent(() => import("@/components/global/ScreenModal"))
  )
  .component("SvgIcon", SvgIcon)
  .component("Icon", Icon)
  .component("Field", Field)
  .component("VeeForm", VeeForm)
  .component("HomeButton", HomeButton)
  .mixin(globalMixin)
  .mixin(labelsMixin)
  .use(store)
  .use(router)
  .mount("#app");
