export default {
  path: "/resources",
  name: "r_resources",
  meta: {
    middlewares: {
      auth: null,
      permission: "RESOURCES"
    }
  },
  component: () => import("../../components/resources/Resources"),
  children: [
    {
      path: "add-resource",
      name: "r_add-resource",
      meta: {
        middlewares: {
          auth: null,
          permission: ["RESOURCES, MANAGE_RESOURCES", "create"]
        }
      },
      component: () => import("../../components/resources/AddResource"),
      children: [
        {
          path: "select-level",
          name: "r_add-resource-select-level",
          meta: {
            middlewares: {
              auth: null,
              permission: ["RESOURCES, MANAGE_RESOURCES", "create"]
            }
          },
          props: {
            backLinkName: "r_add-resource",
            forwardLinkName: "r_add-resource-select-level"
          },
          component: () => import("../../components/resources/ResourceLevels"),
          children: [
            {
              path: "help",
              name: "r_resources-help-online-add-level",
              meta: {
                middlewares: {
                  auth: null,
                  permission: "RESOURCES"
                }
              },
              props: {
                backLinkName: "r_add-resource-select-level",
                slug: "resources-levels"
              },
              component: () => import("../../components/help-online/HelpOnline")
            }
          ]
        },
        {
          path: "select-resource-type",
          name: "r_add-resource-select-resource-type",
          meta: {
            middlewares: {
              auth: null,
              permission: ["RESOURCES, MANAGE_RESOURCES", "create"]
            }
          },
          props: {
            backLinkName: "r_add-resource"
          },
          component: () =>
            import("../../components/resources/SelectResourceType"),
          children: [
            {
              path: "help",
              name: "r_resources-help-online-add-type",
              meta: {
                middlewares: {
                  auth: null,
                  permission: "RESOURCES"
                }
              },
              props: {
                backLinkName: "r_add-resource-select-resource-type",
                slug: "resources-resource-types"
              },
              component: () => import("../../components/help-online/HelpOnline")
            }
          ]
        },
        {
          path: "select-resource-capacity",
          name: "r_add-resource-select-resource-capacity",
          meta: {
            middlewares: {
              auth: null,
              permission: ["RESOURCES, MANAGE_RESOURCES", "create"]
            }
          },
          props: {
            backLinkName: "r_add-resource",
            selectCapacityTypeLinkName:
              "r_add-resource-select-resource-capacity-type",
            actionType: "add"
          },
          component: () =>
            import("../../components/resources/ResourceCapacity"),
          children: [
            {
              path: "help",
              name: "r_resources-help-online-add-capacity",
              meta: {
                middlewares: {
                  auth: null,
                  permission: "RESOURCES"
                }
              },
              props: {
                backLinkName: "r_add-resource-select-resource-capacity",
                slug: "resources-capacity"
              },
              component: () => import("../../components/help-online/HelpOnline")
            }
          ]
        },
        {
          path: "select-resource-model",
          name: "r_add-resource-select-resource-model",
          meta: {
            middlewares: {
              auth: null,
              permission: ["RESOURCES, MANAGE_RESOURCES", "create"]
            }
          },
          props: {
            backLinkName: "r_add-resource"
          },
          component: () =>
            import("../../components/resources/SelectResourceModel"),
          children: [
            {
              path: "help",
              name: "r_resources-help-online-add-models",
              meta: {
                middlewares: {
                  auth: null,
                  permission: "RESOURCES"
                }
              },
              props: {
                backLinkName: "r_add-resource-select-resource-model",
                slug: "resources-models"
              },
              component: () => import("../../components/help-online/HelpOnline")
            }
          ]
        },
        {
          path: "select-function",
          name: "r_add-resource-select-function",
          meta: {
            middlewares: {
              auth: null,
              permission: ["RESOURCES, MANAGE_RESOURCES", "create"]
            }
          },
          props: {
            backLinkName: "r_add-resource"
          },
          component: () =>
            import("../../components/resources/ResourceFunctions"),
          children: [
            {
              path: "help",
              name: "r_resources-help-online-add-functions",
              meta: {
                middlewares: {
                  auth: null,
                  permission: "RESOURCES"
                }
              },
              props: {
                backLinkName: "r_add-resource-select-function",
                slug: "resources-functions"
              },
              component: () => import("../../components/help-online/HelpOnline")
            }
          ]
        },
        {
          path: "name-translations",
          name: "r_add-resource-translate-name",
          meta: {
            middlewares: {
              auth: null,
              permission: ["RESOURCES, MANAGE_RESOURCES", "create"]
            }
          },
          props: {
            backLinkName: "r_add-resource",
            componentTitle: "add-resource"
          },
          component: () =>
            import("../../components/resources/TranslateResource"),
          children: [
            {
              path: "help",
              name: "r_resources-help-online-add-translations",
              meta: {
                middlewares: {
                  auth: null,
                  permission: "RESOURCES"
                }
              },
              props: {
                backLinkName: "r_add-resource-translate-name",
                slug: "resources-translation"
              },
              component: () => import("../../components/help-online/HelpOnline")
            }
          ]
        },
        {
          path: "help",
          name: "r_resources-help-online-add",
          meta: {
            middlewares: {
              auth: null,
              permission: "RESOURCES"
            }
          },
          props: {
            backLinkName: "r_add-resource",
            slug: "resources-add"
          },
          component: () => import("../../components/help-online/HelpOnline")
        }
      ]
    },
    {
      path: "edit-resource",
      name: "r_edit-resource",
      meta: {
        middlewares: {
          auth: null,
          permission: "RESOURCES"
        }
      },
      component: () => import("../../components/resources/EditResource"),
      children: [
        {
          path: "select-level",
          name: "r_edit-resource-select-level",
          meta: {
            middlewares: {
              auth: null,
              permission: "RESOURCES"
            }
          },
          props: {
            backLinkName: "r_edit-resource",
            forwardLinkName: "r_edit-resource-select-level"
          },
          component: () => import("../../components/resources/ResourceLevels"),
          children: [
            {
              path: "help",
              name: "r_resources-help-online-edit-level",
              meta: {
                middlewares: {
                  auth: null,
                  permission: "RESOURCES"
                }
              },
              props: {
                backLinkName: "r_edit-resource-select-level",
                slug: "resources-levels"
              },
              component: () => import("../../components/help-online/HelpOnline")
            }
          ]
        },
        {
          path: "select-resource-type",
          name: "r_edit-resource-select-resource-type",
          meta: {
            middlewares: {
              auth: null,
              permission: "RESOURCES"
            }
          },
          props: {
            backLinkName: "r_edit-resource"
          },
          component: () =>
            import("../../components/resources/SelectResourceType"),
          children: [
            {
              path: "help",
              name: "r_resources-help-online-edit-type",
              meta: {
                middlewares: {
                  auth: null,
                  permission: "RESOURCES"
                }
              },
              props: {
                backLinkName: "r_edit-resource-select-resource-type",
                slug: "resources-resource-types"
              },
              component: () => import("../../components/help-online/HelpOnline")
            }
          ]
        },
        {
          path: "select-resource-capacity",
          name: "r_edit-resource-select-resource-capacity",
          meta: {
            middlewares: {
              auth: null,
              permission: "RESOURCES"
            }
          },
          props: {
            backLinkName: "r_edit-resource",
            selectCapacityTypeLinkName:
              "r_edit-resource-select-resource-capacity-type",
            actionType: "edit"
          },
          component: () =>
            import("../../components/resources/ResourceCapacity"),
          children: [
            {
              path: "select-resource-capacity-type",
              name: "r_edit-resource-select-resource-capacity-type",
              meta: {
                middlewares: {
                  auth: null,
                  permission: "RESOURCES"
                }
              },
              props: {
                backLinkName: "r_edit-resource-select-resource-capacity",
                dateLinkName: "r_edit-resource-capacity-type-time"
              },
              component: () =>
                import("../../components/resources/SelectResourceCapacityType")
            },
            {
              path: "resource-capacity-type-time",
              name: "r_edit-resource-capacity-type-time",
              props: {
                backLinkName: "r_edit-resource-select-resource-capacity-type"
              },
              component: () =>
                import("../../components/resources/ResourceCapacityTypeTime")
            },
            {
              path: "help",
              name: "r_resources-help-online-edit-capacity",
              meta: {
                middlewares: {
                  auth: null,
                  permission: "RESOURCES"
                }
              },
              props: {
                backLinkName: "r_edit-resource-select-resource-capacity",
                slug: "resources-capacity"
              },
              component: () => import("../../components/help-online/HelpOnline")
            }
          ]
        },
        {
          path: "select-resource-model",
          name: "r_edit-resource-select-resource-model",
          meta: {
            middlewares: {
              auth: null,
              permission: "RESOURCES"
            }
          },
          props: {
            backLinkName: "r_edit-resource"
          },
          component: () =>
            import("../../components/resources/SelectResourceModel"),
          children: [
            {
              path: "help",
              name: "r_resources-help-online-edit-models",
              meta: {
                middlewares: {
                  auth: null,
                  permission: "RESOURCES"
                }
              },
              props: {
                backLinkName: "r_edit-resource-select-resource-model",
                slug: "resources-models"
              },
              component: () => import("../../components/help-online/HelpOnline")
            }
          ]
        },
        {
          path: "select-function",
          name: "r_edit-resource-select-function",
          meta: {
            middlewares: {
              auth: null,
              permission: "RESOURCES"
            }
          },
          props: {
            backLinkName: "r_edit-resource",
            forwardLinkName: "r_edit-resource-edit-function"
          },
          component: () =>
            import("../../components/resources/ResourceFunctions"),
          children: [
            {
              path: ":function_id",
              name: "r_edit-resource-edit-function",
              meta: {
                middlewares: {
                  auth: null,
                  permission: "RESOURCES"
                }
              },
              props: {
                backLinkName: "r_edit-resource-select-function"
              },
              component: () =>
                import("../../components/resources/ResourceFunction"),
              children: [
                {
                  path: "help",
                  name: "r_resources-help-online-edit-function",
                  meta: {
                    middlewares: {
                      auth: null,
                      permission: "RESOURCES"
                    }
                  },
                  props: {
                    backLinkName: "r_edit-resource-edit-function",
                    slug: "resources-functions-serial"
                  },
                  component: () =>
                    import("../../components/help-online/HelpOnline")
                },
                {
                  path: "linked-resources",
                  name: "r_edit-resource-edit-function-edit-linked-resources",
                  meta: {
                    middlewares: {
                      auth: null,
                      permission: "RESOURCES"
                    }
                  },
                  props: {
                    backLinkName: "r_edit-resource-edit-function"
                  },
                  component: () =>
                    import("../../components/resources/EditLinkedResources")
                },
                {
                  path: "distance-resources",
                  name: "r_edit-resource-edit-function-edit-distance-resources",
                  meta: {
                    middlewares: {
                      auth: null,
                      permission: "RESOURCES"
                    }
                  },
                  props: {
                    backLinkName: "r_edit-resource-edit-function"
                  },
                  component: () =>
                    import("../../components/resources/EditDistanceResources")
                },
                {
                  path: "language",
                  name: "r_edit-resource-edit-function-language",
                  meta: {
                    middlewares: {
                      auth: null,
                      permission: "RESOURCES"
                    }
                  },
                  props: {
                    backLinkName: "r_edit-resource-edit-function"
                  },
                  component: () =>
                    import("../../components/global/LanguageSelection")
                },
                {
                  path: "device-template",
                  name: "r_edit-resource-edit-function-device-template",
                  meta: {
                    middlewares: {
                      auth: null,
                      permission: ["RESOURCES"]
                    }
                  },
                  props: { backLinkName: "r_edit-resource-edit-function" },
                  component: () =>
                    import("../../components/global/DeviceTemplates")
                },
                {
                  path: "currency",
                  name: "r_edit-resource-edit-function-currency",
                  meta: {
                    middlewares: {
                      auth: null,
                      permission: ["RESOURCES"]
                    }
                  },
                  props: { backLinkName: "r_edit-resource-edit-function" },
                  component: () =>
                    import("../../components/global/CurrencySelection")
                }
              ]
            },
            {
              path: "help",
              name: "r_resources-help-online-edit-functions",
              meta: {
                middlewares: {
                  auth: null,
                  permission: "RESOURCES"
                }
              },
              props: {
                backLinkName: "r_edit-resource-select-function",
                slug: "resources-functions"
              },
              component: () => import("../../components/help-online/HelpOnline")
            }
          ]
        },
        {
          path: "availability",
          name: "r_edit-resource-availability",
          meta: {
            middlewares: {
              auth: null,
              permission: "RESOURCES"
            }
          },
          component: () =>
            import("../../components/resources/ResourceAvailability"),
          children: [
            {
              path: "add-availability",
              name: "r_edit-resource-add-availability",
              meta: {
                middlewares: {
                  auth: null,
                  permission: "RESOURCES"
                }
              },
              props: {
                backLinkName: "r_edit-resource-availability"
              },
              component: () =>
                import("../../components/resources/AddResourceAvailability"),
              children: [
                {
                  path: "help",
                  name: "r_resources-help-online-add-availibility",
                  meta: {
                    middlewares: {
                      auth: null,
                      permission: "RESOURCES"
                    }
                  },
                  props: {
                    backLinkName: "r_edit-resource-add-availability",
                    slug: "resources-availability-seasons"
                  },
                  component: () =>
                    import("../../components/help-online/HelpOnline")
                }
              ]
            },
            {
              path: "add-unavailability",
              name: "r_edit-resource-add-unavailability",
              meta: {
                middlewares: {
                  auth: null,
                  permission: "RESOURCES"
                }
              },
              props: {
                backLinkName: "r_edit-resource-availability"
              },
              component: () =>
                import("../../components/resources/AddResourceUnavailability"),
              children: [
                {
                  path: "help",
                  name: "r_resources-help-online-add-unavailability",
                  meta: {
                    middlewares: {
                      auth: null,
                      permission: "RESOURCES"
                    }
                  },
                  props: {
                    backLinkName: "r_edit-resource-add-unavailability",
                    slug: "resources-availability-periods"
                  },
                  component: () =>
                    import("../../components/help-online/HelpOnline")
                }
              ]
            },
            {
              path: "edit-availability/:resource_id/:availability_id",
              name: "r_edit-resource-edit-availability",
              meta: {
                middlewares: {
                  auth: null,
                  permission: "RESOURCES"
                }
              },
              props: {
                backLinkName: "r_edit-resource-availability"
              },
              component: () =>
                import("../../components/resources/EditResourceAvailability"),
              children: [
                {
                  path: "help",
                  name: "r_resources-help-online-edit-availibility",
                  meta: {
                    middlewares: {
                      auth: null,
                      permission: "RESOURCES"
                    }
                  },
                  props: {
                    backLinkName: "r_edit-resource-edit-availability",
                    slug: "resources-availability-seasons"
                  },
                  component: () =>
                    import("../../components/help-online/HelpOnline")
                }
              ]
            },
            {
              path: "edit-unavailability/:resource_id/:unavailability_id",
              name: "r_edit-resource-edit-unavailability",
              meta: {
                middlewares: {
                  auth: null,
                  permission: "RESOURCES"
                }
              },
              props: {
                backLinkName: "r_edit-resource-availability"
              },
              component: () =>
                import("../../components/resources/EditResourceUnavailability"),
              children: [
                {
                  path: "help",
                  name: "r_resources-help-online-edit-unavailability",
                  meta: {
                    middlewares: {
                      auth: null,
                      permission: "RESOURCES"
                    }
                  },
                  props: {
                    backLinkName: "r_edit-resource-edit-unavailability",
                    slug: "resources-availability-periods"
                  },
                  component: () =>
                    import("../../components/help-online/HelpOnline")
                }
              ]
            },
            {
              path: "help",
              name: "r_resources-help-online-availability",
              meta: {
                middlewares: {
                  auth: null,
                  permission: "RESOURCES"
                }
              },
              props: {
                backLinkName: "r_edit-resource-availability",
                slug: "resources-availability"
              },
              component: () => import("../../components/help-online/HelpOnline")
            }
          ]
        },
        {
          path: "resource-attributes",
          name: "r_edit-resource-select-resource-attributes",
          meta: {
            middlewares: {
              auth: null,
              permission: "RESOURCES"
            }
          },
          props: {
            backLinkName: "r_edit-resource"
          },
          component: () =>
            import("../../components/resources/SelectResourceAttributes"),
          children: [
            {
              path: "help",
              name: "r_resources-help-online-edit-attributes",
              meta: {
                middlewares: {
                  auth: null,
                  permission: "RESOURCES"
                }
              },
              props: {
                backLinkName: "r_edit-resource-select-resource-attributes",
                slug: "resources-resource-attributes"
              },
              component: () => import("../../components/help-online/HelpOnline")
            }
          ]
        },
        {
          path: "resource-services",
          name: "r_edit-resource-select-resource-services",
          meta: {
            middlewares: {
              auth: null,
              permission: "RESOURCES"
            }
          },
          props: {
            backLinkName: "r_edit-resource"
          },
          component: () =>
            import("../../components/resources/SelectResourceServices")
        },
        {
          path: "name-translations",
          name: "r_edit-resource-translate-name",
          meta: {
            middlewares: {
              auth: null,
              permission: "RESOURCES"
            }
          },
          props: {
            backLinkName: "r_edit-resource",
            componentTitle: "edit-resource"
          },
          component: () =>
            import("../../components/resources/TranslateResource"),
          children: [
            {
              path: "help",
              name: "r_resources-help-online-edit-translations",
              meta: {
                middlewares: {
                  auth: null,
                  permission: "RESOURCES"
                }
              },
              props: {
                backLinkName: "r_edit-resource-translate-name",
                slug: "resources-translation"
              },
              component: () => import("../../components/help-online/HelpOnline")
            }
          ]
        },
        {
          path: "qr-code",
          name: "r_edit-resource-qr-code",
          meta: {
            middlewares: {
              auth: null,
              permission: "RESOURCES"
            }
          },
          props: {
            backLinkName: "r_edit-resource"
          },
          component: () =>
            import("../../components/resources/EditResourceQrCode"),
          children: [
            {
              path: "help",
              name: "r_resources-help-online-edit-qr-code",
              meta: {
                middlewares: {
                  auth: null,
                  permission: "RESOURCES"
                }
              },
              props: {
                backLinkName: "r_edit-resource-qr-code",
                slug: "resources-qr-code"
              },
              component: () => import("../../components/help-online/HelpOnline")
            }
          ]
        },
        {
          path: "image",
          name: "r_edit-resource-image",
          meta: {
            middlewares: {
              auth: null,
              permission: ["RESOURCES", "update"]
            }
          },
          component: () =>
            import("../../components/resources/ResourceImageSelection"),
          children: [
            {
              path: "help",
              name: "r_resources-help-online-edit-images",
              meta: {
                middlewares: {
                  auth: null,
                  permission: "RESOURCES"
                }
              },
              props: {
                backLinkName: "r_edit-resource-image",
                slug: "resources-images"
              },
              component: () => import("../../components/help-online/HelpOnline")
            }
          ]
        },
        {
          path: "help",
          name: "r_resources-help-online-edit",
          meta: {
            middlewares: {
              auth: null,
              permission: "RESOURCES"
            }
          },
          props: {
            backLinkName: "r_edit-resource",
            slug: "resources-edit"
          },
          component: () => import("../../components/help-online/HelpOnline")
        }
      ]
    },
    {
      path: "search-filters",
      name: "r_resources-search-filters",
      meta: {
        middlewares: {
          auth: null,
          permission: "RESOURCES"
        }
      },
      props: {
        backLinkName: "r_resources",
        searchFiltersStore: "searchFiltersResources"
      },
      component: () => import("../../components/search/SearchFiltersResources")
    },
    {
      path: "help",
      name: "r_resources-help-online",
      meta: {
        middlewares: {
          auth: null,
          permission: "RESOURCES"
        }
      },
      props: {
        backLinkName: "r_resources",
        slug: "resources"
      },
      component: () => import("../../components/help-online/HelpOnline")
    }
  ]
};
