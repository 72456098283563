import { apiEndpoints } from "@/services/constants";
import httpServiceAuth from "@/services/http-service";

export default {
  state: {
    title: null,
    requiredAttendees: [],
    optionalAttendees: [],
    start: null,
    end: null,
    enhancedLocation: [],
    resources: [],
    suggestedResources: null,
    loading: false,
    recurrence: null
  },
  mutations: {
    setTitle(state, payload) {
      state.title = payload;
    },
    setRequiredAttendees(state, payload) {
      state.requiredAttendees = payload;
    },
    setOptionalAttendees(state, payload) {
      state.optionalAttendees = payload;
    },
    setStart(state, payload) {
      state.start = payload;
    },
    setEnd(state, payload) {
      state.end = payload;
    },
    setEnhancedLocation(state, payload) {
      state.enhancedLocation = payload;
    },
    setResources(state, payload) {
      state.resources = payload;
    },
    setSuggestedResources(state, payload) {
      state.suggestedResources = payload;
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    setRecurrence(state, payload) {
      state.recurrence = payload;
    }
  },
  actions: {
    setTitle({ commit }, title) {
      commit("setTitle", title);
    },
    setRequiredAttendees({ commit }, requiredAttendees) {
      commit("setRequiredAttendees", requiredAttendees);
    },
    setOptionalAttendees({ commit }, optionalAttendees) {
      commit("setOptionalAttendees", optionalAttendees);
    },
    setStart({ commit }, start) {
      commit("setStart", start);
    },
    setEnd({ commit }, end) {
      commit("setEnd", end);
    },
    setEnhancedLocation({ commit }, enhancedLocation) {
      commit("setEnhancedLocation", enhancedLocation);
    },
    setRecurrence({ commit }, recurrence) {
      commit("setRecurrence", recurrence);
    },
    getResource({ commit, state }, payload) {
      commit("loader/setScreenLoading", true, { root: true });
      commit("setLoading", true);
      return httpServiceAuth
        .post(apiEndpoints.company.outlookResource, payload)
        .then(response => {
          commit("setResources", [...state.resources, response.data.data]);
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
          commit("setLoading", false);
        });
    },
    getSuggestedResources({ commit }, payload) {
      commit("loader/setScreenLoading", true, { root: true });
      commit("setLoading", true);
      return httpServiceAuth
        .get(apiEndpoints.company.outlookResources, { params: payload })
        .then(response => {
          commit("setSuggestedResources", response.data);
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
          commit("setLoading", false);
        });
    }
  }
};
