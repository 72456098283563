import { APPLICATIONS } from "@/services/consts/application";

const VM_MIDDLEWARES = {
  auth: null,
  permission: "VISITOR_MANAGEMENT",
  document: { applicationId: APPLICATIONS.APP_VISITOR.id },
  menuPreferences: "VISITOR_MANAGEMENT"
};

export default {
  path: "/visitor-management",
  name: "r_visitor-management",
  meta: {
    middlewares: VM_MIDDLEWARES
  },
  component: () =>
    import("../../components/visitor-management/VisitorManagement"),
  children: [
    {
      path: ":receptionDeskId",
      name: "r_visitor-management-reception-desk",
      meta: {
        middlewares: VM_MIDDLEWARES
      },
      props: {
        backLinkName: "r_visitor-management"
      },
      component: () =>
        import("../../components/visitor-management/ReceptionDesk"),
      children: [
        {
          path: "add-visitor",
          name: "r_reception-desk-add-visitor",
          meta: {
            middlewares: VM_MIDDLEWARES
          },
          props: {
            backLinkName: "r_visitor-management-reception-desk"
          },
          component: () =>
            import("../../components/visitor-management/AddVisitor"),
          children: [
            {
              path: "host",
              name: "r_reception-desk-add-visitor-select-host",
              meta: {
                middlewares: VM_MIDDLEWARES
              },
              props: {
                actionType: "add",
                backLinkName: "r_reception-desk-add-visitor"
              },
              component: () =>
                import("../../components/visitor-management/SelectHost")
            },
            {
              path: "language",
              name: "r_reception-desk-add-visitor-select-language",
              meta: {
                middlewares: VM_MIDDLEWARES
              },
              props: {
                backLinkName: "r_reception-desk-add-visitor"
              },
              component: () =>
                import("../../components/global/LanguageSelection"),
              children: [
                {
                  path: "help",
                  name: "r_visitor-add-languages-help-online",
                  meta: {
                    middlewares: VM_MIDDLEWARES
                  },
                  props: {
                    backLinkName:
                      "r_reception-desk-add-visitor-select-language",
                    slug: "visitor-languages"
                  },
                  component: () =>
                    import("../../components/help-online/HelpOnline")
                }
              ]
            },
            {
              path: "containers",
              name: "r_reception-desk-add-containers-to-visitor",
              meta: {
                middlewares: VM_MIDDLEWARES
              },
              props: {
                backLinkName: "r_reception-desk-add-visitor"
              },
              component: () =>
                import(
                  "../../components/visitor-management/AddAssignableContainersToVisitor"
                ),
              children: [
                {
                  path: "help",
                  name: "r_visitor-add-containers-help-online",
                  meta: {
                    middlewares: VM_MIDDLEWARES
                  },
                  props: {
                    backLinkName: "r_reception-desk-add-containers-to-visitor",
                    slug: "visitor-containers"
                  },
                  component: () =>
                    import("../../components/help-online/HelpOnline")
                }
              ]
            },
            {
              path: "help",
              name: "r_visitor-add-help-online",
              meta: {
                middlewares: VM_MIDDLEWARES
              },
              props: {
                backLinkName: "r_reception-desk-add-visitor",
                slug: "visitor-new"
              },
              component: () => import("../../components/help-online/HelpOnline")
            }
          ]
        },
        {
          path: ":visitorId",
          name: "r_reception-desk-edit-visitor",
          meta: {
            middlewares: VM_MIDDLEWARES
          },
          props: {
            backLinkName: "r_visitor-management-reception-desk"
          },
          component: () =>
            import("../../components/visitor-management/EditVisitor"),
          children: [
            {
              path: "visits",
              name: "r_reception-desk-edit-visitor-visits",
              meta: {
                middlewares: VM_MIDDLEWARES
              },
              props: {
                backLinkName: "r_reception-desk-edit-visitor"
              },
              component: () =>
                import("../../components/visitor-management/Visit/Visits"),
              children: [
                {
                  path: ":visitId",
                  name: "r_reception-desk-edit-visitor-edit-visit",
                  meta: {
                    middlewares: VM_MIDDLEWARES
                  },
                  props: {
                    backLinkName: "r_reception-desk-edit-visitor-visits"
                  },
                  component: () =>
                    import("../../components/visitor-management/Visit/Visit"),
                  children: [
                    {
                      path: "host",
                      name: "r_reception-desk-edit-visit-select-host",
                      meta: {
                        middlewares: VM_MIDDLEWARES
                      },
                      props: {
                        actionType: "add",
                        backLinkName: "r_reception-desk-edit-visitor-edit-visit"
                      },
                      component: () =>
                        import("../../components/visitor-management/SelectHost")
                    }
                  ]
                },
                {
                  path: "add",
                  name: "r_reception-desk-edit-visitor-add-visit",
                  meta: {
                    middlewares: VM_MIDDLEWARES
                  },
                  props: {
                    backLinkName: "r_reception-desk-edit-visitor-visits"
                  },
                  component: () =>
                    import(
                      "../../components/visitor-management/Visit/AddVisit"
                    ),
                  children: [
                    {
                      path: "host",
                      name: "r_reception-desk-add-visit-select-host",
                      meta: {
                        middlewares: VM_MIDDLEWARES
                      },
                      props: {
                        actionType: "add",
                        backLinkName: "r_reception-desk-edit-visitor-add-visit"
                      },
                      component: () =>
                        import("../../components/visitor-management/SelectHost")
                    }
                  ]
                },
                {
                  path: "help",
                  name: "r_visitor-edit-visit-help-online",
                  meta: {
                    middlewares: VM_MIDDLEWARES
                  },
                  props: {
                    backLinkName: "r_reception-desk-edit-visitor-visits",
                    slug: "visitor-visit"
                  },
                  component: () =>
                    import("../../components/help-online/HelpOnline")
                }
              ]
            },
            {
              path: "language",
              name: "r_reception-desk-edit-visitor-select-language",
              meta: {
                middlewares: VM_MIDDLEWARES
              },
              props: {
                backLinkName: "r_reception-desk-edit-visitor"
              },
              component: () =>
                import("../../components/global/LanguageSelection"),
              children: [
                {
                  path: "help",
                  name: "r_visitor-edit-languages-help-online",
                  meta: {
                    middlewares: VM_MIDDLEWARES
                  },
                  props: {
                    backLinkName:
                      "r_reception-desk-edit-visitor-select-language",
                    slug: "visitor-languages"
                  },
                  component: () =>
                    import("../../components/help-online/HelpOnline")
                }
              ]
            },
            {
              path: "containers",
              name: "r_reception-desk-edit-add-containers-to-visitor",
              meta: {
                middlewares: VM_MIDDLEWARES
              },
              props: {
                backLinkName: "r_reception-desk-edit-visitor"
              },
              component: () =>
                import(
                  "../../components/visitor-management/AddAssignableContainersToVisitor"
                ),
              children: [
                {
                  path: "help",
                  name: "r_visitor-edit-containers-help-online",
                  meta: {
                    middlewares: VM_MIDDLEWARES
                  },
                  props: {
                    backLinkName:
                      "r_reception-desk-edit-add-containers-to-visitor",
                    slug: "visitor-containers"
                  },
                  component: () =>
                    import("../../components/help-online/HelpOnline")
                }
              ]
            },
            {
              path: "help",
              name: "r_visitor-edit-help-online",
              meta: {
                middlewares: VM_MIDDLEWARES
              },
              props: {
                backLinkName: "r_reception-desk-edit-visitor",
                slug: "visitor-edit"
              },
              component: () => import("../../components/help-online/HelpOnline")
            }
          ]
        },
        {
          path: "help",
          name: "r_visitor-reception-desk-help-online",
          meta: {
            middlewares: VM_MIDDLEWARES
          },
          props: {
            backLinkName: "r_visitor-management-reception-desk",
            slug: "visitor-reception-desk"
          },
          component: () => import("../../components/help-online/HelpOnline")
        }
      ]
    },
    {
      path: "help",
      name: "r_visitor-help-online",
      meta: {
        middlewares: VM_MIDDLEWARES
      },
      props: {
        backLinkName: "r_visitor-management",
        slug: "visitor"
      },
      component: () => import("../../components/help-online/HelpOnline")
    }
  ]
};
